<template>

  <div>

    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
      >

        <b-card no-body>
          <b-card-header>
                
            <div>
              <b-card-title class="mb-1">
                Visible Settings
              </b-card-title>
              
            </div>
          </b-card-header>
          <b-card-body>

            <b-table sticky-header
              ref="refUserListTable"
              class="position-relative"
              :items="dataList"
              responsive
              :fields="tableColumns"
              show-empty
              empty-text="No records found"
            >

             <template #cell(form_name)="items">
             
                  <p class="wordBreak">{{ items.item.form_name | capitalize}}</p>
               
              </template> 

              <template #cell(action)="items">
             
                  <div class="demo-inline-spacing">

                    <div>
                      
                      <b-form-checkbox
                        value="yes"
                        unchecked-value="no"
                        class="custom-control-warning"
                        switch
                        v-model="items.item.status"
                        :key="items.item.form_id"
                        @change="updateSetting(items.item)"
                      />
                    </div>

                  </div>
               
              </template> 

            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb,BCardBody,BFormCheckbox,BCardHeader,BCardTitle
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { POST_API } from "../../../store/actions.type";
import Bus from "../../../event-bus";
import flatPickr from 'vue-flatpickr-component';
import moment from "moment-timezone";

export default {
  
  components: {
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BBreadcrumb,
    flatPickr,
    BCardHeader,
    BCardTitle
  },
  data() {
    return {
       tableColumns: [
            { key: 'form_name', label: 'Form Name', sortable: false , thStyle:  {width: '70%'}},
            { key: 'action', label: 'Visible', sortable: false , thStyle:  {width: '30%'}},
            
          ],
        
        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,

       
    }
  },
  methods : {
    dataList(){

      return this.$store.dispatch(POST_API, {
         data:{
         },
         api: '/api/get-off-reports'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.items = this.$store.getters.getResults.data;
              
              return this.items;
          }
      });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Reports',
      },{
        to:{name:'custom-forms-dashboard'},
        text:'Form Dashboard',
        
      },{
        to:null,
        text: 'Visible Settings',
        active:true
      }];
      return item;
    },
    updateSetting(item){
      
      return this.$store.dispatch(POST_API, {
         data:{
          item:item
         },
         api: '/api/update-off-report'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              var data = this.$store.getters.getResults;
              
              this.filterTable()
              
          }
      });
    }
  },
  mounted(){
    
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
